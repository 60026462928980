<template>
  <v-app>
    <!-- <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="../assets/logo-white.png"
          transition="scale-transition"
          width="30"
        />
      </div>
      <v-spacer></v-spacer>
    </v-app-bar> -->

    <v-main>
      <v-container fluid>
        <v-row class="text-center">
          <v-col cols="12" class="mt-8">
            <v-img :src="appLogo" class="my-3" contain height="60" />
          </v-col>

          <v-col cols="12" class="mb-4">
            <h1 class="display-2 font-weight-normal mb-3">
              Welcome to the lair
            </h1>

            <p class="subheading font-weight-regular"></p>
          </v-col>
          <v-col cols="12">
            <div id="firebaseui-auth-container"></div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { firebaseUI } from "../firebase";
// import app from "../firebase";
import "firebaseui/dist/firebaseui.css";
import { mapGetters } from "vuex";
import config from "../../config/config";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
    }),
  },
  data() {
    const hostname = window.location.hostname;
    let brandAppName = config.brandAppName;
    let brandAppLogo = config.brandAppLogo;
    if (
      hostname.startsWith("zportal.ejmaly.com") ||
      hostname.startsWith("z-partner.web.app")
    ) {
      brandAppName = config.ejmalyAppName;
      brandAppLogo = config.ejmalyAppLogo;
    }
    return {
      appName: brandAppName,
      appLogo: brandAppLogo,
    };
  },
  async mounted() {
    if (this.isLoggedIn) {
      this.$router.push("/home");
    }
    if (!this.isLoggedIn) {
      let self = this;
      const uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: function (e) {
            self.$emit("login-success", e.user);
          },
        },
        //signInFlow: "popup",
        signInOptions: [
          // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        credentialHelper: "none",
      };
      firebaseUI.start("#firebaseui-auth-container", uiConfig);
    }
  },
};
</script>
